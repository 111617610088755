<template>
    <div class="drag-box" v-drag id="drag" :style="{fontSize: `${data.fontSize}px`, left: `${data.left}px`, top: `${data.top}px`}">
        {{title}}
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                default: 'title'
            },
            data: {
                default: {
                    top: 100,
                    left: 100,
                    fontSize: 100,
                }
            }
        },
        directives: {
            drag: {
                // 指令的定义
                bind: function(el, binding, vnode) {
                    // console.log(vnode.context._props.data.fontSize)
                    let oDiv = el;  // 获取当前元素
                    oDiv.onmousedown = (e) => {
                        // console.log('onmousedown')
                        // 算出鼠标相对元素的位置
                        let disX = e.clientX - oDiv.offsetLeft;
                        let disY = e.clientY - oDiv.offsetTop;

                        document.onmousemove = (e) => {
                            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                            let left = e.clientX - disX;
                            let top = e.clientY - disY;

                            oDiv.style.left = left + 'px';
                            oDiv.style.top = top + 'px';
                            console.log(oDiv.style.left, oDiv.style.top, oDiv.style.fontSize)
                            vnode.context.$emit('pos', {left: left, top: top, fontSize: vnode.context._props.data.fontSize})

                        };

                        // eslint-disable-next-line no-unused-vars
                        document.onmouseup = (e) => {
                            document.onmousemove = null;
                            document.onmouseup = null;
                        }
                    }
                }
            }
        },
    }
</script>

<style scoped>
    .drag-box {
        position:absolute;
        top: 100px;
        left:100px;
        text-align: center;
        padding: 0;
        /*background: #fff;*/
        /*border-radius: 5px;*/
        /*box-shadow: 0 4px 12px rgba(0,0,0,.15);*/
        white-space:nowrap;
        cursor: pointer;
        user-select: none;
    }
</style>