<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="证书设置"/>
        <div style="margin-top: 20px;display: flex;max-height: 800px">
            <div class="cert-box">
                <div class="img-box" v-if="form.img">
                    <img :src="form.img">
                    <drag-box title="张三" :data="form.name" @pos="posName"/>
                    <drag-box title="JJ2022030200001155" :data="form.certNum" @pos="posCertNum"/>
                    <drag-box title="230604198202250000" :data="form.idNum" @pos="posIdNum"/>
                    <drag-box title="2022年04月15日" :data="form.certDate" @pos="posCertDate"/>
                </div>
                <div v-else style="text-align: center">
                    <el-upload class="upload-demo" drag action="/api/upload/" :headers="uploadHeader"
                               :on-success="handleUploadSuccess"
                               :before-upload="beforeUpload">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg格式文件，分辨率为3388x2394像素</div>
                    </el-upload>
                </div>
            </div>
            <div class="toolbox">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>证书设置</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="removeImg">删除图片</el-button>
                    </div>
                    <el-form :model="form" ref="form" class="form">
                        <el-form-item label="证书名称" prop="title"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>
                        <el-form-item label="姓名设置" prop="name"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                            <div style="display: flex;width: 100%">
                                <el-input v-model="form.name.top"></el-input>
                                <el-input v-model="form.name.left"></el-input>
                                <el-input v-model="form.name.fontSize"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="证书编号" prop="certNum"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                            <div style="display: flex;width: 100%">
                                <el-input v-model="form.certNum.top"></el-input>
                                <el-input v-model="form.certNum.left"></el-input>
                                <el-input v-model="form.certNum.fontSize"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="idNum"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                            <div style="display: flex;width: 100%">
                                <el-input v-model="form.idNum.top"></el-input>
                                <el-input v-model="form.idNum.left"></el-input>
                                <el-input v-model="form.idNum.fontSize"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="证书日期" prop="certDate"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                            <div style="display: flex;width: 100%">
                                <el-input v-model="form.certDate.top"></el-input>
                                <el-input v-model="form.certDate.left"></el-input>
                                <el-input v-model="form.certDate.fontSize"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submit">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
    import dragBox from "@/pages/certs/widgets/dragBox";

    export default {
        components: {
            dragBox
        },
        data() {
            return {
                uploadHeader: {
                    Authorization: localStorage.getItem('token')
                },
                form: {
                    title: '',
                    img: '',
                    name: {
                        left: 780, top: 960, fontSize: 100
                    },
                    certNum: {
                        left: 880, top: 1710, fontSize: 80
                    },
                    idNum: {
                        left: 880, top: 1836, fontSize: 80
                    },
                    certDate: {
                        left: 2161, top: 1877, fontSize: 80
                    },
                },
            }
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            handleUploadSuccess(res, file) {
                if (res.state === 200) {
                    // this.form.img = URL.createObjectURL(file.raw);
                    // this.form.img = 'http://localhost:8000/uploads/2022/4/1649979890716.jpg'
                    // this.form.img = "http://localhost:8000" + res.data
                    this.form.img = res.data
                } else {
                    this.$message.error(res.msg)
                }
            },
            async beforeUpload(file) {
                // 上传图片前处理函数
                await new Promise(function(resolve, reject) {
                    let width = 3388;
                    let height = 2394;
                    let _URL = window.URL || window.webkitURL;
                    let image = new Image();
                    image.onload = function() {
                        const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
                        const isLt5M = file.size / 1024 / 1024 < 5;
                        let isSize = image.width === width && image.height === height;
                        console.log(isJPG, isLt5M, isSize)
                        isJPG && isLt5M && isSize ? resolve() : reject();
                    };
                    image.src = _URL.createObjectURL(file);
                }).then(
                    () => {
                        return file;
                    },
                    () => {
                        this.$message.error("上传图片尺寸不符合,只能是3388x2394像素、jpg格式5M以下文件!");
                        return Promise.reject();
                    }
                );
            },
            async submit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(error => error);
                if (!valid) return
                console.log(this.form)
                const resp = await this.$http.post("/cert/", this.form)
                if (resp.data.state === 200) {
                    this.$message.success("保存成功")
                    await this.$router.push('/main/certs')
                }
            },
            posName(e) {
                // console.log(e)
                this.form.name = e
            },
            posCertNum(e) {
                this.form.certNum = e
            },
            posIdNum(e) {
                this.form.idNum = e
            },
            posCertDate(e){
                this.form.certDate = e
            },
            removeImg(){
                this.form.img = ''
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.$http.get(`/cert/${this.$route.query.id}`).then(resp => {
                    console.log(resp.data.data)
                    this.form = resp.data.data
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cert-box {
        position: relative;
        flex: 1;
        overflow: scroll;

        .img-box {
            transform: scale(0.30);
            transform-origin: 0 0;
            width: 1000px;
            height: 500px;

            img {
                width: 3388px;
            }
        }
    }

    .toolbox {
        width: 300px;
        padding: 20px;
    }

    .box-card {
        height: 100%;
    }
</style>